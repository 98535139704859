<template>
  <v-card :elevation="elevation" height="auto" class="acc">
    <div class="acc__header">
      <div class="left">
        <span class="title">
          {{ title }}
        </span>
      </div>
      <div class="right">
        <v-icon @click="opened = !opened">
          {{ opened ? "mdi-chevron-up" : "mdi-chevron-down" }}
        </v-icon>
      </div>
    </div>

    <div v-if="opened" class="acc__body">
      <slot name="content" />
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    elevation: { type: [String, Number], default: 1 },
    title: { type: String, default: "Título" },
  },
  data() {
    return {
      opened: false,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.acc {
  padding: $z-s-1;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-weight: 700;
      font-size: .93rem !important;
    }
  }

  &__body {
    margin-top: $z-s-1;
  }
}
</style>
