var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-sender-card" },
    [
      _vm._m(0),
      _c("v-form", { ref: "form" }, [
        _c("div", { staticClass: "new-sender-card__body my-4" }, [
          _c(
            "div",
            { staticClass: "item" },
            [
              _c("z-input", {
                attrs: {
                  hideDetails: true,
                  rules: [(v) => !!v || "Campo obrigatório"],
                  label: "Country Code",
                  type: "text",
                },
                model: {
                  value: _vm.sender.country_code,
                  callback: function ($$v) {
                    _vm.$set(_vm.sender, "country_code", $$v)
                  },
                  expression: "sender.country_code",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "item" },
            [
              _c("z-input", {
                attrs: {
                  hideDetails: true,
                  rules: [(v) => !!v || "Campo obrigatório"],
                  label: "Phone Number",
                  type: "text",
                },
                model: {
                  value: _vm.sender.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.sender, "phone", $$v)
                  },
                  expression: "sender.phone",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "item" },
            [
              _c("z-input", {
                attrs: {
                  hideDetails: true,
                  rules: [(v) => !!v || "Campo obrigatório"],
                  label: "Display Name",
                  type: "text",
                },
                model: {
                  value: _vm.sender.display_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.sender, "display_name", $$v)
                  },
                  expression: "sender.display_name",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "item" },
            [
              _c("z-autocomplete", {
                attrs: {
                  items: _vm.types,
                  rules: [(v) => !!v || "Campo obrigatório"],
                  hideDetails: true,
                  label: "Type",
                },
                model: {
                  value: _vm.sender.type,
                  callback: function ($$v) {
                    _vm.$set(_vm.sender, "type", $$v)
                  },
                  expression: "sender.type",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "item" },
            [
              _c("z-autocomplete", {
                attrs: {
                  items: _vm.langs,
                  rules: [(v) => !!v || "Campo obrigatório"],
                  hideDetails: true,
                  label: "Locale",
                },
                model: {
                  value: _vm.sender.locale,
                  callback: function ($$v) {
                    _vm.$set(_vm.sender, "locale", $$v)
                  },
                  expression: "sender.locale",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass:
            "new-sender-card--actions d-flex align-center justify-end",
        },
        [
          _c("z-btn", {
            staticClass: "mr-2",
            attrs: { text: "Cancelar", small: "", rounded: false },
            on: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          }),
          _c("z-btn", {
            attrs: {
              text: "Cadsatrar",
              primary: "",
              small: "",
              color: "primary",
              rounded: false,
            },
            on: { click: _vm.submitInfos },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "new-sender-card__title" }, [
      _c("strong", [_vm._v("Configuração de sender")]),
      _c("br"),
      _c("small", [
        _vm._v("Insira abaixo as informações para criar um novo sender"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }