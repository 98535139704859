var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "acc", attrs: { elevation: _vm.elevation, height: "auto" } },
    [
      _c("div", { staticClass: "acc__header" }, [
        _c("div", { staticClass: "left" }, [
          _c("span", { staticClass: "title" }, [
            _vm._v(" " + _vm._s(_vm.title) + " "),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "v-icon",
              {
                on: {
                  click: function ($event) {
                    _vm.opened = !_vm.opened
                  },
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.opened ? "mdi-chevron-up" : "mdi-chevron-down") +
                    " "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm.opened
        ? _c("div", { staticClass: "acc__body" }, [_vm._t("content")], 2)
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }