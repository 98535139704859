var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showInformations,
              expression: "showInformations",
            },
          ],
          staticClass: "wrapper",
        },
        [
          _c("div", { staticClass: "wrapper__card" }, [
            _vm._m(0),
            _c("div", { staticClass: "wrapper__card__body" }, [
              _c(
                "div",
                { staticClass: "wrapper__card__body__form" },
                [
                  _c(
                    "v-form",
                    { ref: "form" },
                    [
                      _c(
                        "v-tabs",
                        {
                          attrs: { "align-with-title": "", "fixed-tabs": "" },
                          model: {
                            value: _vm.tab,
                            callback: function ($$v) {
                              _vm.tab = $$v
                            },
                            expression: "tab",
                          },
                        },
                        [
                          _c("v-tab", [_vm._v("SMS")]),
                          _c("v-tab", [_vm._v("E-mail")]),
                          _c("v-tab", [_vm._v("Push")]),
                          _c("v-tab", [_vm._v("Campanha")]),
                          _c("v-tab", [_vm._v("Social")]),
                          _c("v-tab", [_vm._v("WhatsApp")]),
                        ],
                        1
                      ),
                      _c(
                        "v-tabs-items",
                        {
                          staticStyle: { padding: "10px 1px" },
                          model: {
                            value: _vm.tab,
                            callback: function ($$v) {
                              _vm.tab = $$v
                            },
                            expression: "tab",
                          },
                        },
                        [
                          _c(
                            "v-tab-item",
                            { key: "sms" },
                            [
                              _c("Accordion", {
                                staticClass: "mb-5",
                                attrs: { title: "Credenciais" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "content",
                                    fn: function () {
                                      return [
                                        _c("div", { staticClass: "list" }, [
                                          _c(
                                            "div",
                                            { staticClass: "list__item" },
                                            [
                                              _c("z-select", {
                                                attrs: {
                                                  label:
                                                    "Selecione o provedor de envio",
                                                  itemText: "name",
                                                  itemValue: "value",
                                                  rules: [
                                                    (v) =>
                                                      !!v ||
                                                      "Campo obrigatório",
                                                  ],
                                                  "hide-details": true,
                                                  items: _vm.dataTypes,
                                                  type: "text",
                                                },
                                                model: {
                                                  value:
                                                    _vm.credentialForm
                                                      .smsProvider,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.credentialForm,
                                                      "smsProvider",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "credentialForm.smsProvider",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm.credentialForm.smsProvider &&
                                          _vm.credentialForm.smsProvider ===
                                            "Infobip"
                                            ? _c(
                                                "div",
                                                { staticClass: "list__item" },
                                                [
                                                  _c("z-input", {
                                                    attrs: {
                                                      hideDetails: true,
                                                      rules: [
                                                        (v) =>
                                                          !!v ||
                                                          "Campo obrigatório",
                                                      ],
                                                      label:
                                                        "Api Key da Infobip",
                                                      type: "text",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.credentialForm
                                                          .infobipApiKey,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.credentialForm,
                                                          "infobipApiKey",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "credentialForm.infobipApiKey",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.credentialForm.smsProvider &&
                                          _vm.credentialForm.smsProvider ===
                                            "Infobip"
                                            ? _c(
                                                "div",
                                                { staticClass: "list__item" },
                                                [
                                                  _c("z-input", {
                                                    attrs: {
                                                      hideDetails: true,
                                                      rules: [
                                                        (v) =>
                                                          !!v ||
                                                          "Campo obrigatório",
                                                      ],
                                                      label:
                                                        "Url base da infobip",
                                                      type: "text",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.credentialForm
                                                          .infobipBaseUrl,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.credentialForm,
                                                          "infobipBaseUrl",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "credentialForm.infobipBaseUrl",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.credentialForm.smsProvider &&
                                          _vm.credentialForm.smsProvider ===
                                            "Infobip"
                                            ? _c(
                                                "div",
                                                { staticClass: "list__item" },
                                                [
                                                  _c("z-input", {
                                                    attrs: {
                                                      hideDetails: true,
                                                      rules: [
                                                        (v) =>
                                                          !!v ||
                                                          "Campo obrigatório",
                                                      ],
                                                      label:
                                                        "Domínio do encurtador de URL",
                                                      type: "text",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.credentialForm
                                                          .urlShortnerDomain,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.credentialForm,
                                                          "urlShortnerDomain",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "credentialForm.urlShortnerDomain",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.credentialForm.smsProvider &&
                                          _vm.credentialForm.smsProvider ===
                                            "Zenvia"
                                            ? _c(
                                                "div",
                                                { staticClass: "list__item" },
                                                [
                                                  _c("z-input", {
                                                    attrs: {
                                                      hideDetails: true,
                                                      rules: [
                                                        (v) =>
                                                          !!v ||
                                                          "Campo obrigatório",
                                                      ],
                                                      label: "Token do Zenvia",
                                                      type: "text",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.credentialForm
                                                          .zenviaToken,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.credentialForm,
                                                          "zenviaToken",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "credentialForm.zenviaToken",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.credentialForm.smsProvider &&
                                          _vm.credentialForm.smsProvider ===
                                            "Zenvia"
                                            ? _c(
                                                "div",
                                                { staticClass: "list__item" },
                                                [
                                                  _c("z-input", {
                                                    attrs: {
                                                      hideDetails: true,
                                                      rules: [
                                                        (v) =>
                                                          !!v ||
                                                          "Campo obrigatório",
                                                      ],
                                                      label:
                                                        "Keyword do Zenvia",
                                                      type: "text",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.credentialForm
                                                          .zenviaKeyword,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.credentialForm,
                                                          "zenviaKeyword",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "credentialForm.zenviaKeyword",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.credentialForm.smsProvider &&
                                          _vm.credentialForm.smsProvider ===
                                            "Aws"
                                            ? _c(
                                                "div",
                                                { staticClass: "list__item" },
                                                [
                                                  _c("z-input", {
                                                    attrs: {
                                                      hideDetails: true,
                                                      rules: [
                                                        (v) =>
                                                          !!v ||
                                                          "Campo obrigatório",
                                                      ],
                                                      label: "Tópico ARN",
                                                      type: "text",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.credentialForm
                                                          .awsTopicArn,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.credentialForm,
                                                          "awsTopicArn",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "credentialForm.awsTopicArn",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.credentialForm.smsProvider &&
                                          _vm.credentialForm.smsProvider ===
                                            "Aws"
                                            ? _c(
                                                "div",
                                                { staticClass: "list__item" },
                                                [
                                                  _c("z-input", {
                                                    attrs: {
                                                      hideDetails: true,
                                                      rules: [
                                                        (v) =>
                                                          !!v ||
                                                          "Campo obrigatório",
                                                      ],
                                                      label: "Chave de Acesso",
                                                      type: "text",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.credentialForm
                                                          .awsAccessKeyId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.credentialForm,
                                                          "awsAccessKeyId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "credentialForm.awsAccessKeyId",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.credentialForm.smsProvider &&
                                          _vm.credentialForm.smsProvider ===
                                            "Aws"
                                            ? _c(
                                                "div",
                                                { staticClass: "list__item" },
                                                [
                                                  _c("z-input", {
                                                    attrs: {
                                                      hideDetails: true,
                                                      rules: [
                                                        (v) =>
                                                          !!v ||
                                                          "Campo obrigatório",
                                                      ],
                                                      label:
                                                        "Chave de Acesso Secreta",
                                                      type: "text",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.credentialForm
                                                          .awsSecretAccessKey,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.credentialForm,
                                                          "awsSecretAccessKey",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "credentialForm.awsSecretAccessKey",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.credentialForm.smsProvider &&
                                          _vm.credentialForm.smsProvider ===
                                            "Aws"
                                            ? _c(
                                                "div",
                                                { staticClass: "list__item" },
                                                [
                                                  _c("z-input", {
                                                    attrs: {
                                                      hideDetails: true,
                                                      rules: [
                                                        (v) =>
                                                          !!v ||
                                                          "Campo obrigatório",
                                                      ],
                                                      label: "Região",
                                                      type: "text",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.credentialForm
                                                          .awsRegion,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.credentialForm,
                                                          "awsRegion",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "credentialForm.awsRegion",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                              }),
                              _c("Accordion", {
                                attrs: { title: "Configurações de Optout" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "content",
                                    fn: function () {
                                      return [
                                        _c("div", { staticClass: "list" }, [
                                          _c(
                                            "div",
                                            { staticClass: "list__item" },
                                            [
                                              _c("z-select", {
                                                attrs: {
                                                  label:
                                                    "Selecione a palavra chave do opt-out",
                                                  itemText: "name",
                                                  rules: [
                                                    (v) =>
                                                      !!v ||
                                                      "Campo obrigatório",
                                                  ],
                                                  itemValue: "value",
                                                  "hide-details": true,
                                                  items:
                                                    _vm.smsOptoutWordOptions,
                                                  type: "text",
                                                },
                                                model: {
                                                  value:
                                                    _vm.credentialForm
                                                      .smsOptoutWord,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.credentialForm,
                                                      "smsOptoutWord",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "credentialForm.smsOptoutWord",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm.credentialForm.smsProvider &&
                                          _vm.credentialForm.smsOptoutWord !==
                                            "Pare" &&
                                          _vm.credentialForm.smsOptoutWord !==
                                            "Sair"
                                            ? _c(
                                                "div",
                                                { staticClass: "list__item" },
                                                [
                                                  _c("z-input", {
                                                    attrs: {
                                                      hideDetails: true,
                                                      label:
                                                        "Cadastrar outra palavra chave para o opt-out",
                                                      type: "text",
                                                      rules: [
                                                        (v) =>
                                                          !!v ||
                                                          "Campo obrigatório",
                                                      ],
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.otherSmsOptoutMessage,
                                                      callback: function ($$v) {
                                                        _vm.otherSmsOptoutMessage =
                                                          $$v
                                                      },
                                                      expression:
                                                        "otherSmsOptoutMessage",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.credentialForm.smsProvider
                                            ? _c(
                                                "div",
                                                { staticClass: "list__item" },
                                                [
                                                  _c("z-input", {
                                                    attrs: {
                                                      hideDetails: true,
                                                      rules: [
                                                        (v) =>
                                                          !!v ||
                                                          "Campo obrigatório",
                                                      ],
                                                      label:
                                                        "Mensagem padrão de opt-out",
                                                      type: "text",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.credentialForm
                                                          .smsOptoutMessage,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.credentialForm,
                                                          "smsOptoutMessage",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "credentialForm.smsOptoutMessage",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.credentialForm.smsProvider
                                            ? _c(
                                                "div",
                                                { staticClass: "list__item" },
                                                [
                                                  _c("z-input", {
                                                    attrs: {
                                                      hideDetails: true,
                                                      rules: [
                                                        (v) =>
                                                          !!v ||
                                                          "Campo obrigatório",
                                                      ],
                                                      label:
                                                        "Digite a palavra chave para cancelar o opt-out",
                                                      type: "text",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.credentialForm
                                                          .smsOptoutCancelWord,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.credentialForm,
                                                          "smsOptoutCancelWord",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "credentialForm.smsOptoutCancelWord",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.credentialForm.smsProvider
                                            ? _c(
                                                "div",
                                                { staticClass: "list__item" },
                                                [
                                                  _c("z-input", {
                                                    attrs: {
                                                      hideDetails: true,
                                                      rules: [
                                                        (v) =>
                                                          !!v ||
                                                          "Campo obrigatório",
                                                      ],
                                                      label:
                                                        "Mensagem padrão de volta do opt-out",
                                                      type: "text",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.credentialForm
                                                          .smsOptoutComebackMessage,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.credentialForm,
                                                          "smsOptoutComebackMessage",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "credentialForm.smsOptoutComebackMessage",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-tab-item",
                            { key: "email" },
                            [
                              _c("Accordion", {
                                staticClass: "mb-5",
                                attrs: { title: "Credenciais" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "content",
                                    fn: function () {
                                      return [
                                        _c("div", { staticClass: "list" }, [
                                          _c(
                                            "div",
                                            { staticClass: "list__item" },
                                            [
                                              _c("z-select", {
                                                attrs: {
                                                  label:
                                                    "Selecione o provedor de envio",
                                                  itemText: "name",
                                                  itemValue: "value",
                                                  rules: [
                                                    (v) =>
                                                      !!v ||
                                                      "Campo obrigatório",
                                                  ],
                                                  "hide-details": true,
                                                  items: _vm.dataTypesEmail,
                                                  type: "text",
                                                },
                                                model: {
                                                  value:
                                                    _vm.credentialForm
                                                      .emailProvider,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.credentialForm,
                                                      "emailProvider",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "credentialForm.emailProvider",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm.credentialForm.emailProvider &&
                                          _vm.credentialForm.emailProvider ===
                                            "Infobip"
                                            ? _c(
                                                "div",
                                                { staticClass: "list__item" },
                                                [
                                                  _c("z-input", {
                                                    attrs: {
                                                      hideDetails: true,
                                                      label:
                                                        "Api Key da Infobip",
                                                      type: "text",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.credentialForm
                                                          .infobipEmailApiKey,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.credentialForm,
                                                          "infobipEmailApiKey",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "credentialForm.infobipEmailApiKey",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.credentialForm.emailProvider &&
                                          _vm.credentialForm.emailProvider ===
                                            "Infobip"
                                            ? _c(
                                                "div",
                                                { staticClass: "list__item" },
                                                [
                                                  _c("z-input", {
                                                    attrs: {
                                                      hideDetails: true,
                                                      label:
                                                        "Url base da infobip",
                                                      type: "text",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.credentialForm
                                                          .infobipEmailBaseUrl,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.credentialForm,
                                                          "infobipEmailBaseUrl",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "credentialForm.infobipEmailBaseUrl",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.credentialForm.emailProvider &&
                                          _vm.credentialForm.emailProvider ===
                                            "Sparkpost"
                                            ? _c(
                                                "div",
                                                { staticClass: "list__item" },
                                                [
                                                  _c("z-input", {
                                                    attrs: {
                                                      hideDetails: true,
                                                      label:
                                                        "Chave da API do Sparkpost",
                                                      type: "text",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.credentialForm
                                                          .sparkpostApiKey,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.credentialForm,
                                                          "sparkpostApiKey",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "credentialForm.sparkpostApiKey",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.credentialForm.emailProvider
                                            ? _c(
                                                "div",
                                                { staticClass: "list__item" },
                                                [
                                                  _c("z-input", {
                                                    attrs: {
                                                      hideDetails: true,
                                                      label: "E-mail de envio",
                                                      type: "text",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.credentialForm
                                                          .emailSender,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.credentialForm,
                                                          "emailSender",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "credentialForm.emailSender",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.credentialForm.emailProvider
                                            ? _c(
                                                "div",
                                                { staticClass: "list__item" },
                                                [
                                                  _c("z-input", {
                                                    attrs: {
                                                      hideDetails: true,
                                                      label:
                                                        "Dominio de envio de email",
                                                      type: "text",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.credentialForm
                                                          .emailDomain,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.credentialForm,
                                                          "emailDomain",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "credentialForm.emailDomain",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.credentialForm.emailProvider
                                            ? _c(
                                                "div",
                                                { staticClass: "list__item" },
                                                [
                                                  _c("z-input", {
                                                    attrs: {
                                                      hideDetails: true,
                                                      label:
                                                        "Nome para envio de email",
                                                      type: "text",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.credentialForm
                                                          .senderName,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.credentialForm,
                                                          "senderName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "credentialForm.senderName",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.credentialForm.emailProvider
                                            ? _c(
                                                "div",
                                                { staticClass: "list__item" },
                                                [
                                                  _c("z-input", {
                                                    attrs: {
                                                      hideDetails: true,
                                                      label: "Responder Para",
                                                      type: "text",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.credentialForm
                                                          .replyTo,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.credentialForm,
                                                          "replyTo",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "credentialForm.replyTo",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-tab-item",
                            { key: "push" },
                            [
                              _c("Accordion", {
                                staticClass: "mb-5",
                                attrs: { title: "Credenciais" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "content",
                                    fn: function () {
                                      return [
                                        _c("div", { staticClass: "list" }, [
                                          _c(
                                            "div",
                                            { staticClass: "list__item" },
                                            [
                                              _c("z-select", {
                                                attrs: {
                                                  label:
                                                    "Selecione o provedor de envio",
                                                  itemText: "name",
                                                  itemValue: "value",
                                                  rules: [
                                                    (v) =>
                                                      !!v ||
                                                      "Campo obrigatório",
                                                  ],
                                                  "hide-details": true,
                                                  items: _vm.pushdataTypes,
                                                  type: "text",
                                                },
                                                model: {
                                                  value:
                                                    _vm.credentialForm
                                                      .pushProvider,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.credentialForm,
                                                      "pushProvider",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "credentialForm.pushProvider",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "list__item" },
                                            [
                                              _vm.credentialForm
                                                .pushProvider === "firebase"
                                                ? _c("z-input", {
                                                    attrs: {
                                                      hideDetails: true,
                                                      label: "Url do Firebase",
                                                      type: "text",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.credentialForm
                                                          .firebaseUrl,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.credentialForm,
                                                          "firebaseUrl",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "credentialForm.firebaseUrl",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm.credentialForm
                                                .pushProvider === "onesignal"
                                                ? _c("z-input", {
                                                    attrs: {
                                                      hideDetails: true,
                                                      label: "App Id",
                                                      type: "text",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.credentialForm
                                                          .oneSignalAppId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.credentialForm,
                                                          "oneSignalAppId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "credentialForm.oneSignalAppId",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "list__item" },
                                            [
                                              _vm.credentialForm
                                                .pushProvider === "firebase"
                                                ? _c("z-input", {
                                                    attrs: {
                                                      hideDetails: true,
                                                      label:
                                                        "Json Service Account",
                                                      type: "text",
                                                      disabled:
                                                        _vm.credentialForm
                                                          .firebaseServiceAccount !=
                                                        "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.credentialForm
                                                          .firebaseServiceAccount,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.credentialForm,
                                                          "firebaseServiceAccount",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "credentialForm.firebaseServiceAccount",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm.credentialForm
                                                .pushProvider === "onesignal"
                                                ? _c("z-input", {
                                                    attrs: {
                                                      hideDetails: true,
                                                      label: "API Key",
                                                      type: "text",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.credentialForm
                                                          .oneSignalApiKey,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.credentialForm,
                                                          "oneSignalApiKey",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "credentialForm.oneSignalApiKey",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _vm.credentialForm.pushProvider ===
                                          "firebase"
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "list__item__input",
                                                },
                                                [
                                                  _c("z-btn", {
                                                    attrs: {
                                                      isLoading:
                                                        _vm.loadingConnect,
                                                      text: "Escolher arquivo",
                                                      primary: "",
                                                      color: "primary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.openFilePicker()
                                                      },
                                                    },
                                                  }),
                                                  _c("input", {
                                                    ref: "file",
                                                    staticStyle: {
                                                      display: "none",
                                                    },
                                                    attrs: {
                                                      type: "file",
                                                      accept: ".json",
                                                    },
                                                    on: {
                                                      change: _vm.previewFiles,
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-tab-item",
                            { key: "campanha" },
                            [
                              _c("Accordion", {
                                staticClass: "mb-5",
                                attrs: { title: "Utm" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "content",
                                    fn: function () {
                                      return [
                                        _c("div", { staticClass: "list" }, [
                                          _c(
                                            "div",
                                            { staticClass: "list__item" },
                                            [
                                              _vm._v(
                                                " Variáveis disponíveis: $channel, $template, $journey "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "list__item" },
                                            [
                                              _c("z-input", {
                                                attrs: {
                                                  hideDetails: true,
                                                  label: "utm_source",
                                                  type: "text",
                                                },
                                                model: {
                                                  value:
                                                    _vm.credentialForm
                                                      .utm_source,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.credentialForm,
                                                      "utm_source",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "credentialForm.utm_source",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "list__item" },
                                            [
                                              _c("z-input", {
                                                attrs: {
                                                  hideDetails: true,
                                                  label: "utm_medium",
                                                  type: "text",
                                                },
                                                model: {
                                                  value:
                                                    _vm.credentialForm
                                                      .utm_medium,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.credentialForm,
                                                      "utm_medium",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "credentialForm.utm_medium",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "list__item" },
                                            [
                                              _c("z-input", {
                                                attrs: {
                                                  hideDetails: true,
                                                  label: "utm_campaign",
                                                  type: "text",
                                                },
                                                model: {
                                                  value:
                                                    _vm.credentialForm
                                                      .utm_campaign,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.credentialForm,
                                                      "utm_campaign",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "credentialForm.utm_campaign",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "list__item" },
                                            [
                                              _c("z-input", {
                                                attrs: {
                                                  hideDetails: true,
                                                  label: "utm_id",
                                                  type: "text",
                                                },
                                                model: {
                                                  value:
                                                    _vm.credentialForm.utm_id,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.credentialForm,
                                                      "utm_id",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "credentialForm.utm_id",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "list__item" },
                                            [
                                              _c("z-input", {
                                                attrs: {
                                                  hideDetails: true,
                                                  label: "utm_term",
                                                  type: "text",
                                                },
                                                model: {
                                                  value:
                                                    _vm.credentialForm.utm_term,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.credentialForm,
                                                      "utm_term",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "credentialForm.utm_term",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "list__item" },
                                            [
                                              _c("z-input", {
                                                attrs: {
                                                  hideDetails: true,
                                                  label: "utm_content",
                                                  type: "text",
                                                },
                                                model: {
                                                  value:
                                                    _vm.credentialForm
                                                      .utm_content,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.credentialForm,
                                                      "utm_content",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "credentialForm.utm_content",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-tab-item",
                            { key: "social" },
                            [
                              _c("Accordion", {
                                staticClass: "mb-5",
                                attrs: { title: "Google Ads" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "content",
                                    fn: function () {
                                      return [
                                        _c("div", { staticClass: "list" }, [
                                          _c(
                                            "div",
                                            { staticClass: "list__item" },
                                            [
                                              _c("z-input", {
                                                attrs: {
                                                  hideDetails: true,
                                                  label:
                                                    "Google Ads Developer Token",
                                                  type: "text",
                                                },
                                                model: {
                                                  value:
                                                    _vm.credentialForm
                                                      .google_developer_token,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.credentialForm,
                                                      "google_developer_token",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "credentialForm.google_developer_token",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "list__item" },
                                            [
                                              _c("z-input", {
                                                attrs: {
                                                  hideDetails: true,
                                                  label:
                                                    "Google Ads OAuth Client Id",
                                                  type: "text",
                                                },
                                                model: {
                                                  value:
                                                    _vm.credentialForm
                                                      .google_oauth_client_id,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.credentialForm,
                                                      "google_oauth_client_id",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "credentialForm.google_oauth_client_id",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "list__item" },
                                            [
                                              _c("z-input", {
                                                attrs: {
                                                  hideDetails: true,
                                                  label:
                                                    "Google Ads OAuth Secret Key",
                                                  type: "text",
                                                },
                                                model: {
                                                  value:
                                                    _vm.credentialForm
                                                      .google_oauth_secret_key,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.credentialForm,
                                                      "google_oauth_secret_key",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "credentialForm.google_oauth_secret_key",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "list__item" },
                                            [
                                              _c("z-input", {
                                                attrs: {
                                                  hideDetails: true,
                                                  label:
                                                    "Google Ads OAuth Refresh Token",
                                                  type: "text",
                                                },
                                                model: {
                                                  value:
                                                    _vm.credentialForm
                                                      .google_oauth_refresh_token,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.credentialForm,
                                                      "google_oauth_refresh_token",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "credentialForm.google_oauth_refresh_token",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                              }),
                              _c("Accordion", {
                                staticClass: "mb-5",
                                attrs: { title: "Facebook" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "content",
                                    fn: function () {
                                      return [
                                        _c("div", { staticClass: "list" }, [
                                          _c(
                                            "div",
                                            { staticClass: "list__item" },
                                            [
                                              _c("z-input", {
                                                attrs: {
                                                  hideDetails: true,
                                                  label: "Ad Account Id",
                                                  type: "text",
                                                },
                                                model: {
                                                  value:
                                                    _vm.credentialForm
                                                      .fb_ad_account_id,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.credentialForm,
                                                      "fb_ad_account_id",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "credentialForm.fb_ad_account_id",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "list__item" },
                                            [
                                              _c("z-input", {
                                                attrs: {
                                                  hideDetails: true,
                                                  label: "Auth Token",
                                                  type: "text",
                                                },
                                                model: {
                                                  value:
                                                    _vm.credentialForm
                                                      .fb_auth_token,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.credentialForm,
                                                      "fb_auth_token",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "credentialForm.fb_auth_token",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-tab-item",
                            { key: "WhatsApp" },
                            [
                              _c("Accordion", {
                                staticClass: "mb-5",
                                attrs: { title: "WhatsApp" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "content",
                                    fn: function () {
                                      return [
                                        _c("div", { staticClass: "list" }, [
                                          _c(
                                            "div",
                                            { staticClass: "list__item" },
                                            [
                                              _c("z-input", {
                                                attrs: {
                                                  hideDetails: true,
                                                  label:
                                                    "WhatsApp Business Account Id",
                                                  type: "text",
                                                },
                                                model: {
                                                  value:
                                                    _vm.credentialForm
                                                      .whatsappBusinessAccountId,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.credentialForm,
                                                      "whatsappBusinessAccountId",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "credentialForm.whatsappBusinessAccountId",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "list__item" },
                                            [
                                              _c("z-input", {
                                                attrs: {
                                                  hideDetails: true,
                                                  label:
                                                    "WhatsApp Business Number",
                                                  type: "text",
                                                },
                                                model: {
                                                  value:
                                                    _vm.credentialForm
                                                      .infobipWhatsappSenders,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.credentialForm,
                                                      "infobipWhatsappSenders",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "credentialForm.infobipWhatsappSenders",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "list__item" },
                                            [
                                              _c("z-input", {
                                                attrs: {
                                                  hideDetails: true,
                                                  label:
                                                    "WhatsApp Display Name",
                                                  type: "text",
                                                },
                                                model: {
                                                  value:
                                                    _vm.credentialForm
                                                      .infobipWhatsappDisplayName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.credentialForm,
                                                      "infobipWhatsappDisplayName",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "credentialForm.infobipWhatsappDisplayName",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "list__item" },
                                            [
                                              _c("z-input", {
                                                attrs: {
                                                  hideDetails: true,
                                                  label:
                                                    "Infobip Whatsapp Api Key",
                                                  type: "text",
                                                },
                                                model: {
                                                  value:
                                                    _vm.credentialForm
                                                      .infobipWhatsappApiKey,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.credentialForm,
                                                      "infobipWhatsappApiKey",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "credentialForm.infobipWhatsappApiKey",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "list__item" },
                                            [
                                              _c("z-input", {
                                                attrs: {
                                                  hideDetails: true,
                                                  label:
                                                    "Infobip Whatsapp Base Url",
                                                  type: "text",
                                                },
                                                model: {
                                                  value:
                                                    _vm.credentialForm
                                                      .infobipWhatsappBaseUrl,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.credentialForm,
                                                      "infobipWhatsappBaseUrl",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "credentialForm.infobipWhatsappBaseUrl",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "list__item actions",
                          staticStyle: { "padding-top": "20px !important" },
                        },
                        [
                          _c(
                            "v-row",
                            { attrs: { justify: "end" } },
                            [
                              _vm.tab <= 2
                                ? _c("z-btn", {
                                    attrs: {
                                      text:
                                        _vm.tab == 2
                                          ? "Testar conexão"
                                          : "Testar envio",
                                      "is-loading": _vm.loadingSubmit,
                                      color: "primary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.tab === 2 &&
                                        _vm.credentialForm &&
                                        _vm.credentialForm.pushProvider ===
                                          "onesignal"
                                          ? _vm.checkTestPushConnection()
                                          : (_vm.showSendTestSMSModal = true)
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _c("z-btn", {
                                staticStyle: { "margin-left": "10px" },
                                attrs: { text: "Limpar", color: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.clear()
                                  },
                                },
                              }),
                              _c("z-btn", {
                                staticStyle: { "margin-left": "10px" },
                                attrs: {
                                  isLoading: _vm.loadingSubmit,
                                  text: "Salvar",
                                  primary: "",
                                  color: "primary",
                                },
                                on: { click: _vm.submitForm },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _vm.showSendTestSMSModal
        ? _c("z-card-modal", {
            attrs: { width: "400", title: _vm.varTitle },
            scopedSlots: _vm._u(
              [
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "cards-audience",
                          staticStyle: {
                            gap: "1rem",
                            padding: "1rem 0",
                            "flex-wrap": "wrap",
                            color: "333333",
                          },
                        },
                        [
                          _c(
                            "div",
                            [
                              _vm.tab === 0
                                ? _c("z-input", {
                                    attrs: {
                                      mask: "+## (##) #####-####",
                                      rules: [
                                        (v) => !!v || "Campo obrigatório",
                                      ],
                                      hideDetails: true,
                                      placeholder: "Número",
                                      type: "text",
                                    },
                                    model: {
                                      value: _vm.testNumber,
                                      callback: function ($$v) {
                                        _vm.testNumber = $$v
                                      },
                                      expression: "testNumber",
                                    },
                                  })
                                : _vm._e(),
                              _vm.tab === 1
                                ? _c("z-input", {
                                    attrs: {
                                      rules: [
                                        (v) => !!v || "Campo obrigatório",
                                      ],
                                      hideDetails: true,
                                      placeholder: "E-mail",
                                      type: "text",
                                    },
                                    model: {
                                      value: _vm.testNumber,
                                      callback: function ($$v) {
                                        _vm.testNumber = $$v
                                      },
                                      expression: "testNumber",
                                    },
                                  })
                                : _vm._e(),
                              _vm.tab === 2
                                ? _c("z-input", {
                                    attrs: {
                                      rules: [
                                        (v) => !!v || "Campo obrigatório",
                                      ],
                                      hideDetails: true,
                                      placeholder: "Token",
                                      type: "text",
                                    },
                                    model: {
                                      value: _vm.testNumber,
                                      callback: function ($$v) {
                                        _vm.testNumber = $$v
                                      },
                                      expression: "testNumber",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "flex-end",
                                "margin-top": "8px",
                              },
                            },
                            [
                              _c("z-btn", {
                                staticStyle: { "margin-right": "10px" },
                                attrs: {
                                  text: "fechar",
                                  "is-loading": _vm.loadingTempalte,
                                  color: "primary",
                                },
                                on: { click: _vm.closeTestModal },
                              }),
                              _c("z-btn", {
                                attrs: {
                                  text: "Enviar",
                                  primary: "",
                                  "is-loading": _vm.loadingTempalte,
                                  color: "primary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.sendTestSMS()
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1620493309
            ),
            model: {
              value: _vm.showSendTestSMSModal,
              callback: function ($$v) {
                _vm.showSendTestSMSModal = $$v
              },
              expression: "showSendTestSMSModal",
            },
          })
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { width: "600" },
          model: {
            value: _vm.newSenderdialog,
            callback: function ($$v) {
              _vm.newSenderdialog = $$v
            },
            expression: "newSenderdialog",
          },
        },
        [
          _vm.newSenderdialog
            ? _c("NewSenderModal", { on: { close: _vm.closeNewSenderModal } })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showInformations,
              expression: "!showInformations",
            },
          ],
          staticClass: "wrapper no-data",
        },
        [_c("div", { staticClass: "wrapper__card" }, [_c("z-loading")], 1)]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "wrapper__card__header" }, [
      _c("div", { staticClass: "left" }, [
        _c("span", [_vm._v("Credenciais de mensageria")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }